import { Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const TADataFetcher = () => {
  const [signal, setSignal] = useState(null);
  const [direction, setDirection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch data from an API route
  const fetchData = async (url, setData) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data from ${url}: ${response.statusText}`);
      }
      
      const result = await response.json();
      if (result.data && result.data.length > 0) {
        // Get the most recent event
        const mostRecent = result.data[result.data.length - 1];
        setData(mostRecent);
      } else {
        setData(null);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);

      await Promise.all([
        fetchData("https://blindfoldcapital.com/api/ta/signal", setSignal),
        fetchData("https://blindfoldcapital.com/api/ta/direction", setDirection),
      ]);

      setLoading(false);
    };

    fetchAllData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Text>Blindfold Composite (1m) Signal</Text>
      <section>
        
        {signal ? (
          <p>
            <strong>Signal:</strong> {signal.status} <br />
           
          </p>
        ) : (
          <p>No signal data available.</p>
        )}
      </section>

      <section>
        
        {direction ? (
          <p>
            <strong>Direction:</strong> {direction.status} <br />
            <strong>Timestamp:</strong> {direction.timestamp}
          </p>
        ) : (
          <p>No direction data available.</p>
        )}
      </section>
    </div>
  );
};

export default TADataFetcher;
