import React from "react";
import { Box, Center, HStack, Text, IconButton } from "@chakra-ui/react";
import { RepeatIcon } from "@chakra-ui/icons";
import { Doughnut } from "react-chartjs-2"; // Assuming you are using chart.js for the doughnut chart

const CollateralCard = ({
  totalPositions,
  totalCollateral,
  utilizationRate,
  availableCollateral,
  handleReload,
}) => {


  
    // Data for Doughnut chart
    const data = {
      labels: [`Used: $${totalCollateral}`, `Avail.: $${availableCollateral}`],
      datasets: [
        {
          label: "Collateral",
          data: [utilizationRate, 100 - utilizationRate], // Utilized and available percentage
          backgroundColor: ["#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#36A2EB", "#FFCE56"],
        },
      ],
    };
    const options = {
      cutout: "40%", // Make it look like a meter
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: "Collateral Utilization", // Title for the chart
          font: {
            size: 13,
          },
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const label = tooltipItem.label;
              const value = Number(tooltipItem.raw).toFixed(2); // Ensure raw is a number and then format to 2 decimal places
              return `${label}: ${value}%`; // Custom tooltip labels showing percentage
            },
          },
        },
      },
    };

  return (
    <Box
      
      //maxWidth="250px"      // Max width for larger screens
      w='100%'
      maxWidth="320px"
      //textAlign="center"
      mb={1}
      borderWidth="1px"
      fontSize='sm'
      shadow="md"
      bg="white"
    >
      <HStack justify="center" mt={1}  mb={1}>
        <Text as="b">Positions ({totalPositions})</Text>
        <IconButton
          size="xs"
          variant="outline"
          bg="white"
          colorScheme="messenger"
          icon={<RepeatIcon />}
          onClick={handleReload}
          aria-label="Reload"
        />
      </HStack>
      {/**<Text fontSize='sm'>Collateral: ${totalCollateral}</Text> */}

      {/* Doughnut Chart for Utilization */}
      <Box
        //width="100%"
        
        h="auto"
        minHeight={"135px"}
        textAlign="center"
        //bg="gray.100"
      >
        <Box  maxHeight={["100px", "120px"]} >
          <Doughnut data={data} options={options} key={utilizationRate} />
          <Text w="100%" textAlign="center" fontSize='sm' mt={-1} mb={4}>
            {utilizationRate}%
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CollateralCard;
