import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import VaultPriceFeed from "../../contracts/VaultPriceFeed.json";
import { AppContext } from "../../AppContext";

const FetchBtcPriceBase = ({ onBtcPriceUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { provider } = useContext(AppContext);
const btc = '0x152b9d0FdC40C096757F570A51E494bd4b943E50';
  // Function to fetch the BTC price
  const fetchBtcPrice = async () => {
    try {
      // Initialize routerContract with the VaultPriceFeed contract
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address, // Ensure this address is correctly defined in your VaultPriceFeed.json
        VaultPriceFeed.abi,
        provider
      );

      // Fetch the price from the contract
      const acceptablePrice = await routerContract.getPriceV1(btc, false, true);
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(acceptablePrice);
      const rawPrice = priceInWei.div(scaleFactor).toNumber() / 1000;

      // Format the price to two decimal places
      const formattedPrice = parseFloat(rawPrice.toFixed(2));

      // Update parent with the new price
      if (onBtcPriceUpdate) {
        onBtcPriceUpdate(formattedPrice); // Pass the price to parent component
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching BTC.b price:", error);
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBtcPrice(); // Fetch on mount

    // Set an interval to fetch every 3 minutes (180,000 ms)
    const intervalId = setInterval(() => {
      fetchBtcPrice();
    }, 180000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [onBtcPriceUpdate]); // Empty dependency array to run once on mount

  // No return statement or JSX in this component
};

export default FetchBtcPriceBase;
