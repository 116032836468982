import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import DataCallerBalances from "../api/DataCallerBalances";
import DataExecutionFees from "../api/DataExecutionFees";
import DataCollateralUsed from "../api/DataCollateralUsed";
import DataTokenPriceBtc from "../api/DataTokenPriceBtc";
import DataTokenPriceWeth from "../api/DataTokenPriceWeth";
import DataTokenPriceWavax from "../api/DataTokenPriceWavax";
import DataLiquidityBtc from "../api/DataLiquidityBtc";
import DataLiquidityWeth from "../api/DataLiquidityWeth";
import DataLiquidityWavax from "../api/DataLiquidityWavax";
import DataNetwork from "../api/DataNetwork";
import DataRuntime from "../api/DataRuntime";
import DataBlockNumber from "../api/DataBlockNumber";
import DataIndicators from "../api/DataIndicators";
import DataRatio from "../api/DataRatio";
import DataStartingValue from "../api/DataStartingValue";
import WithdrawalRequests from "./gmx/events/WithdrawalRequest";
import ApprovalGranted from "./gmx/events/ApprovalGranted";
import RecentPositionIncreases from "./gmx/events/RecentPositionIncrease";
import TokenDeposits from "./gmx/events/TokenDeposits";
import TokenWithdrawalRequests from "./gmx/events/TokenWithdrawalRequests";
import DataWavaxBollinger from "../api/DataWavaxBollinger";
import WavaxRsiIndicator from "../api/WavaxRsiIndicator";
import DataWavaxRsi from "../api/DataWavaxRsi";
import DataWavaxSma from "../api/DataWavaxSma";
import DataUsdcBalance from "../api/DataUsdcBalance";

const DataTabs = () => {
  const [marketTabIndex, setMarketTabIndex] = useState(0);

  return (
    <Tabs variant='line' p={0} size={'sm'} >
      <TabList>
        <Tab>Network</Tab>
        <Tab>Price</Tab>
        <Tab>Indicators</Tab>
        {/**<Tab>Expenditures</Tab> */}
        <Tab>Liquidity</Tab>
        
      </TabList>

      <TabPanels>
      <TabPanel >
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            Network
          </Text>
          {/**<Box bg="white" m={1} p={1}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Current Runtime
            </Text>
            <DataRuntime />
          </Box> */}

          
            <Box >
              <Text fontSize="md" fontWeight="bold" mb={2}>
              Gas Price
            </Text>
            <DataNetwork />
          
            </Box>

          
                  

          {/**<Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Block
            </Text>
            <DataBlockNumber />
          </Box> */}
        </TabPanel>
        <TabPanel>
          <Box w='100%'>
            <Text fontSize="md" fontWeight="bold" mb={2}>
                    WAVAX Price
                  </Text>
                  <DataTokenPriceWavax />
                
          </Box>
        </TabPanel>

        <TabPanel>
        <Box >
                  <Text fontSize="md" fontWeight="bold" >
                    WAVAX RSI
                  </Text>
                  <DataWavaxRsi/>
                </Box>

                <Box >
                  <Text fontSize="md" fontWeight="bold" >
                    WAVAX SMA
                  </Text>
                  <DataWavaxSma />
                </Box>
        </TabPanel>
        {/**<TabPanel>
          

          <Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Execution Fees
            </Text>
            <DataExecutionFees />
          </Box>
          <Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Collateral Spending
            </Text>
            <DataCollateralUsed />
          </Box>

          <Box bg="white" m={1} p={1}>
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Starting Position Value
            </Text>
            <DataStartingValue />
          </Box>
        </TabPanel> */}

        {/**<TabPanel>
            
             
              <TabPanel mb={12}>
                

                <Box bg="white" m={1} p={1}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Ratio
                  </Text>
                  <DataRatio />
                </Box>

                <Box bg="white" mb={4}>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Bollinger Wavax
                  </Text>
                  <DataWavaxBollinger />
                </Box>

                
              </TabPanel> 

              
            </TabPanels>
          </Tabs>
        </TabPanel> */}
        <TabPanel  >
                
                <Box >
                  <Text fontSize="md" fontWeight="bold" >
                    WAVAX Liquidity
                  </Text>
                  <DataLiquidityWavax />
                </Box>
                {/**<Box bg="white" m={1} p={1} maxWidth='800px'>
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    WETH Liquidity
                  </Text>
                  <DataLiquidityWeth />
                </Box> */}
                
              </TabPanel>
        {/**<TabPanel>
            <Text>Events</Text>

            <Box p={2}>
            <WithdrawalRequests />
            </Box>

            <Box p={2}>
                <ApprovalGranted />
            </Box>

            <Box p={2}>
                <TokenDeposits />
            </Box>

            <Box p={2}>
                <TokenWithdrawalRequests />
            </Box>

            <Box p={2}>
                <RecentPositionIncreases / >
            </Box>
        </TabPanel> */}

        
      </TabPanels>
    </Tabs>
  );
};

export default DataTabs;
