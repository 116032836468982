import React, { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";

const DataSignal = () => {
  const [signal, setSignal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSignal = async () => {
    try {
      setLoading(true);
      const response = await fetch("https://blindfoldcapital.com/api/ta/signal");

      if (!response.ok) {
        throw new Error("Failed to fetch signal data.");
      }

      const result = await response.json();

      if (result.data && result.data.length > 0) {
        // Get the most recent signal
        const mostRecentSignal = result.data[result.data.length - 1];
        setSignal(mostRecentSignal.status);
      } else {
        setSignal(null);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSignal();
  }, []);

  if (loading) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  return (
    <Text fontSize="sm" color='white' fontWeight="bold">
      ({signal || "No signal"})
    </Text>
  );
};

export default DataSignal;
