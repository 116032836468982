import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { IconButton } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";

function LogoutButton() {
  const { setContractAddress, setContractName, contractAddress } = useContext(AppContext);

  // Function to handle the logout and clear context states
  const handleLogout = () => {
    setContractAddress(null); // Clear the contractAddress state
    setContractName(""); // Clear the contractName state
  };

  return (
    <>
        {contractAddress && (
        <IconButton
      icon={<FiLogOut />}
      aria-label="Logout"
      onClick={handleLogout}
      variant={"unstyled"}
      colorScheme="red"
      size="sm"
      ml={1}
    />
    )}
    </>
  );
}

export default LogoutButton;
