import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataUsdcBalance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://blindfoldcapital.com/api/balances/";

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();

      // Get the timestamp for two days ago
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

      // Filter data for the last 2 days and only USDC token
      const filteredData = result.data.filter(
        (entry) =>
          entry.token === "USDC" && new Date(entry.timestamp) >= twoDaysAgo
      );

      setData(filteredData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;
  if (data.length === 0) return <Text>No data available for the last 2 days</Text>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const labels = data.map((entry) => formatDate(entry.timestamp));
  const balances = data.map((entry) => parseFloat(entry.balance).toFixed(2));

  const mostRecentEntry = data[data.length - 1];
  const mostRecentBalance = mostRecentEntry ? parseFloat(mostRecentEntry.balance).toFixed(2) : "N/A";
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : "N/A";

  const chartData = {
    labels,
    datasets: [
      {
        label: "USDC Balance",
        data: balances,
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
        fill: true,
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Balance (USDC)",
        },
        ticks: {
          callback: function (value) {
            return `$${parseFloat(value).toFixed(2)}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  return (
    <Box p={2} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      <Box mb={1}>
        <Text fontSize="sm">
          USDC Balance: ${mostRecentBalance} (as of {mostRecentTimestamp})
        </Text>
      </Box>
      <Line data={chartData} options={chartOptions} />
    </Box>
  );
};

export default DataUsdcBalance;
