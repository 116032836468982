import React, { useState, useEffect } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';
import axios from 'axios';

const DirectionIndicator = () => {
  const [direction, setDirection] = useState(null);
  const [timestamp, setTimestamp] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch direction from API
  const fetchBlindfoldDirection = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://blindfoldcapital.com/api/ta/direction');

      // Access the last item in the data array to get the most recent status and timestamp
      const data = response.data.data;
      const latestEntry = data[data.length - 1];

      // Update state with the most recent status and timestamp
      setDirection(latestEntry.status);
      setTimestamp(latestEntry.timestamp);
      setError(null); // Clear previous errors
    } catch (error) {
      setError(error.message);
      setDirection(null);
      setTimestamp(null);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchBlindfoldDirection();
  }, []);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  return (
    <VStack gap='0px' textAlign="center" >
      
      <Text
        fontSize="sm"
        fontWeight="bold"
        color={direction === 'Uptrend' ? 'green.500' : 'red.500'}
      >
        {direction || 'No Direction Available'}
      </Text>
      {/**{timestamp && (
        <Text fontSize="xs" color="gray.200">
          {formatDate(timestamp)}
        </Text>
      )} */}
    </VStack>
  );
};

export default DirectionIndicator;
