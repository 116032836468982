import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';

const DataCollarStatusMini = () => {
  const [mostRecentEvent, setMostRecentEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/bot_status/';

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      if (result.data && result.data.length > 0) {
        // Get the most recent event
        const mostRecent = result.data[result.data.length - 1];
        setMostRecentEvent(mostRecent);
      } else {
        setMostRecentEvent(null);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;
  if (!mostRecentEvent) return <Text>No data available</Text>;

  // Function to format the date and time without seconds
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  return (
    <Box  >
      <Text >
        Collar Bot 1
      </Text>
      <Text fontSize="md" color="gray.700">
        {formatDate(mostRecentEvent.timestamp)}
      </Text>
      <Text fontSize="md">{mostRecentEvent.status || 'No status available'}</Text>
    </Box>
  );
};

export default DataCollarStatusMini;
