import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  IconButton,
  Button,
  VStack,
  Box,
  Heading,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa'; // Approval icon
import ApprovalSection from './ApprovalSection';
import ApprovalStatus from './ApprovalStatus';
import WithdrawalStatusNotification from './WithdrawalStatusNotification';
import TenetPluginManager from './TenetPluginManager';

const AccountApprovalModal = ({
  owners,
  paymasterAddress,
  paymasterBalance,
  loading,
  error,
  handleApproval,
  formatAddress,
  owner1Approval,
  owner2Approval,
  owner3Approval,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="Manage Account Approvals" aria-label="Approval Tooltip" placement="top">
        <IconButton
          icon={<FaCheckCircle />}
          aria-label="Open Approval Modal"
          onClick={onOpen}
          colorScheme="blue"
          size="md"
          variant="unstyled"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Approval Management</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/**<ApprovalSection
              owners={owners}
              paymasterAddress={paymasterAddress}
              paymasterBalance={paymasterBalance}
              loading={loading}
              error={error}
              handleApproval={handleApproval}
              formatAddress={formatAddress}
            /> */}

            {/* Approval Window Status Section */}
            <VStack p={6} bg="ghostwhite" border="1px solid ThreeDFace" mt={4}>
              <Box margin="0 auto" padding="4px">
                <Box p={2}>
                  <Heading as="h3" size="md" mb="2px">
                    Approval Window Status
                  </Heading>
                  {/**<ApprovalStatus
                    owner1Approval={owner1Approval}
                    owner2Approval={owner2Approval}
                    owner3Approval={owner3Approval}
                  /> */}
                  <WithdrawalStatusNotification
                    owner1Approval={owner1Approval}
                    owner2Approval={owner2Approval}
                    owner3Approval={owner3Approval}
                  />
                </Box>
              </Box>
            </VStack>

            {/* Plugin Manager */}
            <TenetPluginManager />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AccountApprovalModal;
