import React, { useState, useEffect, useContext, forwardRef, useImperativeHandle } from "react";
import { ethers } from "ethers";
import { AppContext } from "./../../AppContext";

const UsdcBalanceFetcher = forwardRef(({ onUsdcBalance }, ref) => {
  const { rpcUrl, contractAddress } = useContext(AppContext);
  const [usdcBalance, setUsdcBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const erc20ABI = [
    "function balanceOf(address account) view returns (uint256)",
  ];

const usdc = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E';

  // Fetch the USDC balance of the JakobWallet contract
  const fetchUsdcBalance = async () => {
    if (!usdc) return;
    setIsLoading(true);
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const usdcContract = new ethers.Contract(usdc, erc20ABI, provider);
      const balance = await usdcContract.balanceOf(contractAddress);
      const formattedBalance = Number(ethers.utils.formatUnits(balance, 6)).toFixed(2); // Format to 2 decimals
      setUsdcBalance(formattedBalance);

      // Pass the formatted balance up to the parent component
      onUsdcBalance(formattedBalance);
    } catch (error) {
      console.error("Error fetching USDC balance:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Expose fetchUsdcBalance as a method via the ref
  useImperativeHandle(ref, () => ({
    reloadBalance: fetchUsdcBalance,
  }));

  useEffect(() => {
    // Initial fetch when component mounts
    fetchUsdcBalance();
  }, []);

  return (
    <>
      {/* Uncomment if you need to display a UI for fetching the balance */}
      {/* <VStack spacing={4} w="100%" p={4} alignItems="center">
        <Box p={4} border="1px solid" borderRadius="md" bg="gray.50" width="100%">
          <Text fontSize="lg" fontWeight="bold">
            USDC Balance
          </Text>
          <Text fontSize="md">
            {usdcBalance !== null ? `${usdcBalance} USDC` : "Balance not available"}
          </Text>
        </Box>
        <Button
          colorScheme="teal"
          onClick={fetchUsdcBalance}
          isLoading={isLoading}
          loadingText="Fetching Balance"
        >
          Refresh USDC Balance
        </Button>
      </VStack> */}
    </>
  );
});

export default UsdcBalanceFetcher;
