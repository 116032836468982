import { Box, Heading, Image, Text, VStack } from "@chakra-ui/react";

function ApplicationTopology() {
  return (
    <Box
      p={{ base: 4, md: 8 }} // Responsive padding: 4 on small screens, 8 on medium and above
      maxW="container.md" // Sets a maximum width for better readability
      mx="auto" // Centers the Box horizontally
    >
      {/* Title */}
      <Heading as="h1" size="xl" textAlign="center" mb={6}>
        Application Topology
      </Heading>

      {/* Image */}
      <Image
        src="applicationTopology.png" // Update this path to where the image is stored
        alt="Application Topology Diagram"
        borderRadius="md"
        w={{ base: "100%", md: "250px" }} // Responsive width: 100% on small screens, 250px on medium and above
        mb={8}
        objectFit="contain" // Ensures the image scales correctly without distortion
      />

      {/* Description */}
      <VStack spacing={6} align="start">
        {/* Backend Section */}
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Backend - VPS Ubuntu Linux Server (Chicago)
          </Heading>
          <Text fontSize={{ base: "sm", md: "md" }}>
            The backend server is hosted on a VPS Ubuntu Linux server located in Chicago. This server runs core processes like the Trade Bot, interacting with blockchain networks and the JacobV1 Contract for automated cryptocurrency asset management.
          </Text>
        </Box>

        {/* Frontend Section */}
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Frontend - Web Interface (Germany)
          </Heading>
          <Text fontSize={{ base: "sm", md: "md" }}>
            The frontend web interface is hosted in Germany and provides a user dashboard. It connects to the backend services to display real-time trading information and enable users to interact with the trading bot settings and data.
          </Text>
        </Box>

        {/* API Server Section */}
        <Box>
          <Heading as="h2" size="md" mb={2}>
            API Server - VPS Ubuntu Linux Server
          </Heading>
          <Text fontSize={{ base: "sm", md: "md" }}>
            The API server is another VPS Ubuntu Linux server that handles requests and processes data exchange between the frontend and backend services. It plays a crucial role in routing data securely and efficiently.
          </Text>
        </Box>

        {/* Storage Section */}
        <Box>
          <Heading as="h2" size="md" mb={2}>
            Storage - Database Cloud Storage Bucket
          </Heading>
          <Text fontSize={{ base: "sm", md: "md" }}>
            Cloud storage is used to securely store trading data and logs. The storage bucket enables quick access and backup capabilities, ensuring data persistence and integrity.
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default ApplicationTopology;
