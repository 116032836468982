import { AppContext } from "./AppContext";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ethers } from "ethers";
import {
  Box,
  Center,
  TabPanel,
  TabPanels,
  Tabs,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  Tab,
  TabList,
  GridItem,
  Grid,
  Wrap,
  WrapItem,
  SimpleGrid,
  Link,
  Image,
} from "@chakra-ui/react";
import NavBar from "./components/NavBar";
import {
  FiHome,
  FiBarChart2,
  FiUser,
  FiDatabase,
  FiSettings,
  FiHelpCircle,
  FiBook,
} from "react-icons/fi";
import VaultPriceFeed from "./contracts/VaultPriceFeed.json";
import FooterMain from "./components/FooterMain";
import JakobWallet from "./contracts/JakobWallet.json";
import TenetBox from "./components/wallet/TenetBox";
import GmxPositions from "./components/gmx/GmxPositions";
import TokenValue from "./components/wallet/TokenValue";
import WalletTabs from "./components/wallet/WalletTabs";
import DataTabs from "./components/DataTabs";
import { formatAddress } from "./utils/formatMetamask";
import LogoutButton from "./components/wallet/LogoutButton";
import CopyToClipboardButton from "./utils/CopyToClipboardButton";
import ActiveContractInfo from "./components/ActiveContractInfo";
import DataTokenPriceWavax from "./api/DataTokenPriceWavax";
import DataWavaxBollinger from "./api/DataWavaxBollinger";
import WavaxRsiIndicator from "./api/WavaxRsiIndicator";
import DataTokenPriceWeth from "./api/DataTokenPriceWeth";
import IndicatorSelector from "./components/wallet/IndicatorSelector";
import BlindfoldInfo from "./components/documentation/BlindfoldInfo";
import ApplicationTopology from "./components/documentation/ApplicationTopology";
import BlindfoldWalletContract from "./components/documentation/BlindfoldWalletContract";
import ContractList from "./components/documentation/ContractList";
import WavaxSmaIndicatorBase from "./components/indicators/WavaxSmaIndicatorBase";
import WavaxKSTIndicatorBase from "./components/indicators/WavaxKnowForSureBase";
import DataWavaxBollingerBase from "./api/DataWavaxBollingerBase";
import WavaxRsiValueBase from "./components/indicators/WavaxRsiValueBase";
//import ResponsiveSignals from "./components/ResponsiveSignals";
import FetchBtcPriceBase from "./components/prices/FetchBtcPriceBase";
import FetchFGIBase from "./components/indicators/FetchFGIBase";
import FetchUsdcToken from "./components/gmx/api/FetchUsdcToken";
import FetchMimToken from "./components/gmx/api/FetchMimToken";
import FetchAvaxToken from "./components/gmx/api/FetchAvaxToken";
import CalculateAvailableLiquidity from "./components/gmx/CalculateAvailableLiquidity";
import CompareUsdcPairs from "./components/gmx/CompareUsdcPairs";
import CompareUsdcToWavax from "./components/gmx/CompareUsdcToWavax";
import LiquidityGrid from "./components/gmx/LiquidityGrid";
import ExpenseCharts from "./components/ExpenseCharts";
import FetchWavaxPriceBase from "./components/prices/FetchWavaxPriceBase";
import FetchWethPriceBase from "./components/prices/FetchWethPriceBase";
import AccountDataGrid from "./components/AccountDataGrid";
import DirectionIndicator from "./api/DirectionIndicator";
import DataSignal from "./api/DataSignal";
function App() {
  const tabOrientation = "vertical"; // Always vertical

  // RPC Connections

  const rpc43114 = "https://api.avax.network/ext/bc/C/rpc";
  const [rpcUrl, setRpcUrl] = useState(rpc43114);
  const [contractAddress, setContractAddress] = useState(JakobWallet.address);
  const walletABI = JakobWallet.abi;
  const [contractName, setContractName] = useState("");
  // State Variables
  const [tradeSettings, setTradeSettings] = useState(null);
  const [chainId, setChainId] = useState(43114);
  const [account, setAccount] = useState("");
  const [accountBalance, setAccountBalance] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCollateral, setTotalCollateral] = useState(0);
  const [totalPositions, setTotalPositions] = useState(0);

  // indicators
  const [kstSignal, setKstSignal] = useState(null);
  const [smaSignal, setSmaSignal] = useState(null);

  const [bollingerSignal, setBollingerSignal] = useState(null); // State to store the signal (BUY/SELL)
  //const [bollingerPrice, setBollingerPrice] = useState(null); // State to store the latest price
  const [error, setError] = useState(null); // State to store any potential errors
  const [rsiInfo, setRsiInfo] = useState({ rsi: null, signal: "HOLD" });
  const [btcPrice, setBtcPrice] = useState(null);
  const [wavaxPrice, setWavaxPrice] = useState(null);
  const [wethPrice, setWethPrice] = useState(null);

  const [fgiSignal, setFgiSignal] = useState("");
  const [fgiValue, setFgiValue] = useState("");

  // Callback function to receive the FGI signal
  const handleFgiSignalChange = (signal) => {
    setFgiSignal(signal);
  };
  // Callback function to receive the FGI value
  const handleFgiValueChange = (signal) => {
    setFgiValue(signal);
  };
  // Callback function to update the BTC price
  const handleBtcPriceUpdate = (newBtcPrice) => {
    setBtcPrice(newBtcPrice);
  };

  // Callback function to update the wavax price
  const handleWavaxPriceUpdate = (newWavaxPrice) => {
    setWavaxPrice(newWavaxPrice);
  };

  const handleWethPriceUpdate = (newWethPrice) => {
    setWethPrice(newWethPrice);
  };
  // Handle signal and RSI changes from the child component
  const handleRsiSignalChange = ({ rsi, signal }) => {
    setRsiInfo({ rsi, signal });
  };
  // Handle signal change from the child component
  const handleBollingerSignalChange = (newBollingerSignal) => {
    setBollingerSignal(newBollingerSignal);
  };

  // Handle the latest price update from the child component
  const handleBollingerPriceUpdate = (newBollPrice) => {
    //setBollingerPrice(newBollPrice);
  };

  // Optionally, handle error updates
  const handleError = (errorMsg) => {
    setError(errorMsg);
  };

  const handleSmaSignalChange = (newSignal) => {
    setSmaSignal(newSignal);
    console.log("KST Signal Updated:", newSignal); // Optionally log or process the signal
  };
  const handleSignalChange = (newSignal) => {
    setKstSignal(newSignal);
    console.log("KST Signal Updated:", newSignal); // Optionally log or process the signal
  };

  const handleTotalCollateralChange = (collateral) => {
    setTotalCollateral(parseFloat(collateral) || 0);
  };

  const handleTotalPositionsChange = (totalPositions) => {
    setTotalPositions(parseFloat(totalPositions) || 0);
  };

  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const [currentPrice, setCurrentPrice] = useState("");
  const fetchPrice = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://api.avax.network/ext/bc/C/rpc"
      );
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider
      );
      const tx = await routerContract.getPriceV1(
        "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
        false,
        true
      );
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(tx);
      const price = priceInWei.div(scaleFactor);
      const adjusted = Number(price.toString()) / 1000;
      setCurrentPrice(adjusted);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch contract name
  const fetchContractName = async () => {
    if (!contractAddress) return; // Skip if no contractAddress
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        contractAddress,
        walletABI,
        provider
      );
      const name = await contract.ContractName();
      setContractName(name);
    } catch (error) {
      console.error("Error fetching contract info:", error);
    }
  };

  useEffect(() => {
    fetchPrice();
    if (contractAddress) {
      fetchContractName();
    }
  }, [contractAddress]);

  // Callback to handle the trade settings data
  const handleDataFetched = (settings) => {
    console.log("Trade settings fetched:", settings);
    setTradeSettings(settings); // Set the fetched data in the state
  };

  const [totalBalance, setTotalBalance] = useState(0);

  const updateTotalBalance = (balance) => {
    setTotalBalance(balance);
  };

  const [activeBalance, setActiveBalance] = useState(0);

  const handleActiveBalanceUpdate = (balance) => {
    setActiveBalance(balance);
  };

  return (
    <AppContext.Provider
      value={{
        account,
        setAccount,
        chainId,
        setChainId,
        accountBalance,
        setAccountBalance,
        walletABI,
        rpcUrl,
        rpc43114,
        provider,
        contractAddress,
        setContractAddress,
        contractName,
        setContractName,
      }}
    >
      <Router>
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden",
          }}
        >
          <div style={{ padding: 0, flexDirection: "row" }}>
            <NavBar loading={isLoading} />
          </div>

          <Routes>
            <Route
              path="/"
              element={
                <Box
                  height="100%"
                  minHeight="100vh"
                  w="auto"
                  bg="#2c2a2b"
                  //justify="center"
                >
                  {/**            <WavaxKSTIndicatorBase onSignalChange={handleSignalChange} />
                  <WavaxSmaIndicatorBase
                    onSignalChange={handleSmaSignalChange}
                  />
                  <DataWavaxBollingerBase
                    onBollingerSignalChange={handleBollingerSignalChange}
                    onBollingerPriceUpdate={handleBollingerPriceUpdate}
                    onError={handleError}
                  />
                  <WavaxRsiValueBase onSignalChange={handleRsiSignalChange} />
                  
                  <FetchFGIBase onFgiValue={handleFgiValueChange} onSignalChange={handleFgiSignalChange} />
       */}
                  <HStack bg="#2c2a2b">
                    <HStack p={1} gap="2px">
                      <Image src={"./btc.png"} w="18px" />
                      <Text fontSize="sm" color="white">
                        ${btcPrice}
                      </Text>

                      
                    </HStack>

                    <HStack p={1} gap="2px">
                      <Image src={"./avax-logo.png"} w="18px" />
                      <Text fontSize="sm" color="white">
                        ${wavaxPrice}
                      </Text>
                    </HStack>

                    <HStack p={1} gap="2px">
                      <Image src={"./eth.png"} w="18px" />
                      <Text fontSize="sm" color="white">
                        ${wethPrice}
                      </Text>
                    </HStack>

                    <DirectionIndicator />

                    <DataSignal />
                  </HStack>

                  <Tabs
                    isLazy
                    bg="white"
                    p={0}
                    //w="100%"
                    variant="enclosed"
                    h="100%"
                    minHeight="800px"
                    //      h="calc(100vh)" // Adjust for header/footer height
                    //display="flex"
                    //flexDirection="row"
                    orientation={tabOrientation}
                  >
                    <TabList
                      width="60px" // Narrow width for icons only
                      bg="#2c2a2b"
                      p={0}
                      //shadow="md"
                      color='white'
                     // borderWidth="1px"
                      gap={8}
                      

                    >
                      <TabItem icon={FiHome} label="Home" />
                      <TabItem icon={FiBarChart2} label="Positions" />
                      <TabItem icon={FiDatabase} label="Data" />
                      <TabItem icon={FiBook} label="Help" />
                    </TabList>

                    <TabPanels>
                      <TabPanel p={0}>
                        {/* Home Tab Content */}
                        {contractAddress && (
                          <HStack
                            justify="left"
                            w="100%"
                            p={1}
                            //mt={1}
                            shadow="md"
                            borderBottom="1px solid gray.100"
                          >
                            {contractAddress && <ActiveContractInfo />}
                          </HStack>
                        )}
                        <WalletTabs />
                      </TabPanel>
                      <TabPanel p={0}>
                        {/* Positions Content */}
                        <HStack
                          justify="left"
                          
                          //p={1}
                          pl={1}
                          shadow="md"
                          borderBottom="1px solid gray.100"
                        >
                          {contractAddress && <ActiveContractInfo />}
                        </HStack>
                      <Wrap w='100%' h='100%'>
                        <WrapItem  w='100%' minWidth='300px' >
                        <TenetBox
                          totalCollateral={totalCollateral}
                          totalPositions={totalPositions}
                          handleDataFetched={handleDataFetched}
                          activeBalance={activeBalance}
                        />
                        </WrapItem>
                        <WrapItem w='100%'>
                        <GmxPositions
                          onTotalCollateralChange={handleTotalCollateralChange}
                          onTotalPositionChange={handleTotalPositionsChange}
                          contractAddress={contractAddress}
                          currentPrice={currentPrice}
                          fetchPrice={fetchPrice}
                          tradeSettings={tradeSettings}
                          handleActiveBalanceUpdate={handleActiveBalanceUpdate}
                        />
                        </WrapItem>
                      </Wrap>
                      </TabPanel>
                      <TabPanel flex="1" display="flex" flexDirection="column">
                        {/* Data Content */}
                        <DataTabs
                          currentPrice={currentPrice}
                          totalCollateral={totalCollateral}
                        />
                      </TabPanel>
                      <TabPanel>
                        <Box width="100%" margin="0 auto">
                          <Tabs
                            variant="line"
                            colorScheme="blue"
                            bg="white"
                            //p={1}
                            size={'sm'}
                          >
                            <TabList>
                              <Tab>Blindfold</Tab>
                              <Tab>Contracts</Tab>
                            </TabList>
                            <TabPanels>
                              <TabPanel p={1}>
                                <BlindfoldInfo />
                              </TabPanel>
                              <TabPanel p={1}>
                                <ContractList />
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        </Box>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <FetchBtcPriceBase onBtcPriceUpdate={handleBtcPriceUpdate} />
                  <FetchWavaxPriceBase
                    onWavaxPriceUpdate={handleWavaxPriceUpdate}
                  />
                  <FetchWethPriceBase
                    onWethPriceUpdate={handleWethPriceUpdate}
                  />
                </Box>
              }
            />
            <Route path="/collar1" 
              element={<><Text>Blindfold Collar 1</Text>
                <AccountDataGrid />
              </>}/>

            
          </Routes>
          <FooterMain
            style={{ flexShrink: 0, position: "sticky", bottom: 0 }}
          />
        </div>
      </Router>
    </AppContext.Provider>
  );

  function TabItem({ icon: IconComponent, label }) {
    return (
      <Popover trigger="hover" placement="right">
        <PopoverTrigger>
          <Tab>
            <IconComponent size={24} />
          </Tab>
        </PopoverTrigger>
        <PopoverContent color='black' width="fit-content" boxShadow='dark-lg' >
          <PopoverBody display="flex" alignItems="center">
            <IconComponent size={24} style={{ marginRight: "4px" }} />
            {label}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }
}

export default App;
