import React from "react";
import {
  Tabs,
  TabList,
  Text,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Image,
  Center,
  VStack,
} from "@chakra-ui/react";
import CreateWallet from "./CreateWallet"; // Import your CreateWallet component
import DeployedWallets from "./DeployedWallets"; // Import your DeployedWallets component
import DataRuntime from "../../api/DataRuntime";
import DataCollarStatusMini from "../../api/DataCollarStatusMini";
import TADataFetcher from "../../api/TADataFetcher";
import DataIndicatorStatusMini from "../../api/DataIndicatorStatusMini";

const WalletTabs = () => {
  return (
    <Tabs size="sm" variant="enclosed" mt={1}>
      <TabList p={0}>
        <Tab>Home</Tab>

        <Tab>Create</Tab>
        <Tab>Load</Tab>
      </TabList>

      <TabPanels>
        <TabPanel p={1}>
          
            <VStack justify='center'>
              <Image w="200px" src="./positionbalancer.png" />
         
              <Box w='100%' maxWidth="250px" p={2} border='1px solid gray' borderRadius={4}>

                <TADataFetcher />
              </Box>
              <Box w='100%' maxWidth="250px">
                
                <DataRuntime />
              </Box>

              <Box w='100%' maxWidth="250px" p={2} border='1px solid gray' borderRadius={4}>
                <DataCollarStatusMini />
              </Box>

           
              
            </VStack>
          
        </TabPanel>

        <TabPanel p={0}>
          <Center>
            <Box p={4} maxWidth="400px">
              <CreateWallet />
            </Box>
          </Center>
        </TabPanel>
        <TabPanel p={0}>
          <Center>
            <Box p={4} maxWidth="400px">
              <DeployedWallets />
            </Box>
          </Center>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default WalletTabs;
