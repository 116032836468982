import React, { useState, useEffect } from "react";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const DataWavaxSma = () => {
  const [smaData, setSmaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://rebekah.cloud/api/wavax_sma/";

  // Helper function to filter data from the last 12 hours
  const filterLast12Hours = (data) => {
    const now = new Date();
    const twelveHoursAgo = new Date(now.getTime() - 12 * 60 * 60 * 1000); // 12 hours ago
    return data.filter((entry) => {
      const timestamp = new Date(entry.timestamp);
      return timestamp >= twelveHoursAgo;
    });
  };

  const fetchSmaValues = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const smaEntries = result.data.filter((indicator) => indicator.name === "WAVAX SMA");

      // Filter for the last 12 hours
      const filteredData = filterLast12Hours(smaEntries);

      setSmaData(filteredData.reverse());
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSmaValues();
    const intervalId = setInterval(fetchSmaValues, 180000);

    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Text>Error: {error}</Text>;

  // Extract most recent "BUY" or "SELL" signal
  const mostRecentBuySellSignal = smaData.find(
    (entry) => entry.value === "BUY" || entry.value === "SELL"
  );
  const mostRecentBuySellInfo = mostRecentBuySellSignal
    ? `${mostRecentBuySellSignal.value} at ${new Date(mostRecentBuySellSignal.timestamp).toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`
    : "No BUY or SELL signals in the last 12 hours";

  // Extract the most recent signal (regardless of type)
  const currentSignal = smaData[0] || null;
  const currentSignalValue = currentSignal ? currentSignal.value : "N/A";
  const currentSignalTimestamp = currentSignal
    ? new Date(currentSignal.timestamp).toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "N/A";

  // Prepare chart data
  const chartData = {
    labels: smaData.map((entry) => new Date(entry.timestamp)),
    datasets: [
      {
        label: "BUY",
        data: smaData.filter((entry) => entry.value === "BUY").map((entry) => entry.signal),
        borderColor: "green",
        backgroundColor: "green",
        tension: 0.1,
      },
      {
        label: "SELL",
        data: smaData.filter((entry) => entry.value === "SELL").map((entry) => entry.signal),
        borderColor: "red",
        backgroundColor: "red",
        tension: 0.1,
      },
      {
        label: "HOLD",
        data: smaData.filter((entry) => entry.value === "HOLD").map((entry) => entry.signal),
        borderColor: "blue",
        backgroundColor: "blue",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time", // Use time scale
        time: {
          unit: "hour",
        },
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Signal Value",
        },
      },
    },
  };

  return (
    <Box width="100%" p={4} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      <Box mb={4}>
        <Text>
          Most Recent BUY/SELL Signal: {mostRecentBuySellInfo}
        </Text>
        <Text>
          Current Signal: {currentSignalValue} (as of {currentSignalTimestamp})
        </Text>
      </Box>
      <Box width="100%" height="500px">
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataWavaxSma;
