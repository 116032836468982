import React from 'react';
import { Box, Heading, Text, Link, Stack, IconButton, useToast, Flex } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const ContractList = () => {
  const toast = useToast();

  const handleCopy = (address) => {
    navigator.clipboard.writeText(address);
    toast({
      title: "Copied to clipboard",
      description: `${address}`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const contracts = [
    { name: "Blindfold Wallet Factory", address: "0x61b4CdDEF175375b1A582A1c9d7cD3c89EF7F21E" },
    { name: "Position Router", address: "0xfff6d276bc37c61a23f06410dce4a400f66420f8" },
    { name: "Router", address: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8" },
    { name: "Order Book", address: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5" },
    { name: "Reader", address: "0x67b789D48c926006F5132BFCe4e976F0A7A63d5D" },
    { name: "Vault", address: "0x9ab2De34A33fB459b538c43f251eB825645e8595" },
    { name: "GMX Price Feed", url: "https://gmx-avax-server.uc.r.appspot.com/prices" },
    { name: "Vault Price Feed", address: "0x27e99387af40e5CA9CE21418552f15F02C8C57E7" },
  ];

  return (
    <Box
      p={{ base: 4, md: 6 }} // Responsive padding: 4 on small screens, 6 on medium and above
      maxW="container.sm" // Sets a maximum width for better readability on larger screens
      mx="auto" // Centers the Box horizontally
      borderWidth="1px"
      borderRadius="md"
      boxShadow="sm"
      bg="white"
    >
      {/* Title */}
      <Heading
        size='md'
        textAlign="center"
        mb={2}
      >
        Contracts
      </Heading>

      {/* Contract Items */}
      <Stack spacing={4}>
        {contracts.map((contract, index) => (
          <Flex
            key={index}
            direction={{ base: "column", sm: "row" }} // Stack vertically on very small screens, horizontally on small and above
            align={{ base: "flex-start", sm: "center" }}
            justify="space-between"
            p={3}
            borderWidth="1px"
            borderRadius="md"
            bg="gray.50"
          >
            {/* Contract Name */}
            <Text
              fontWeight="bold"
              mb={{ base: 2, sm: 0 }} // Margin bottom on small screens for vertical stacking
              fontSize={{ base: "sm", md: "md" }}
            >
              {contract.name}:
            </Text>

            {/* Contract Address or URL */}
            {contract.address ? (
              <Flex align="center" width="100%" justify="space-between">
                <Text
                  as="span"
                  fontFamily="monospace"
                  mr={2}
                  wordBreak="break-all" // Ensures long addresses wrap appropriately
                  fontSize={{ base: "sm", md: "md" }}
                  flex="1"
                >
                  {contract.address}
                </Text>
                <IconButton
                  icon={<CopyIcon />}
                  size="sm"
                  onClick={() => handleCopy(contract.address)}
                  aria-label={`Copy ${contract.name}`}
                  mt={{ base: 2, sm: 0 }} // Margin top on small screens when stacked vertically
                />
              </Flex>
            ) : (
              <Link
                href={contract.url}
                color="blue.500"
                isExternal
                fontSize={{ base: "sm", md: "md" }}
                wordBreak="break-all"
              >
                {contract.url}
              </Link>
            )}
          </Flex>
        ))}
      </Stack>
    </Box>
  );
};

export default ContractList;
