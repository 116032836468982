import React from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

function BlindfoldInfo() {
  return (
    <Box
      p={{ base: 4, md: 8 }} // Responsive padding: 4 on small screens, 8 on medium and above
      maxW="container.md" // Sets a maximum width for better readability
      mx="auto" // Centers the Box horizontally
      bg={useColorModeValue("white", "gray.700")} // Adjust background based on color mode
      borderRadius="md"
      boxShadow="md"
    >
      {/* Main Heading */}
      <Heading
        size='md'
        textAlign="center"
        mb={2}
      >
        Blindfold Balancer
      </Heading>

      {/* Overview */}
      <Text  mb={4} lineHeight="tall">
        Blindfold is an automated cryptocurrency asset management
        application designed to optimize trading positions through dynamic collateral adjustments. 
      </Text>
      

      {/* How It Works */}
      <Heading as="h2" size="md" mt={4} mb={2}>
        How It Works
      </Heading>
      <Text  mb={6} lineHeight="tall">
        Blindfold monitors the exchange price for selected token assets and
        opens, increases, or decreases position sizes based on positive/negative
        price movements, technical indicators and risk analysis.
      </Text>

      {/* Strategies */}
      <Heading as="h2" size="md" mt={4} mb={2}>
        Strategies
      </Heading>
      <List spacing={4} >
        <ListItem>
          <ListIcon as={FaCheckCircle} color="teal.500" />
          <Text as="span" fontWeight="bold">
            Collar:
          </Text>{" "}
          Uses a leveraged ‘collar’ strategy by maintaining both a long and short
          position for each asset to reduce risk and capitalize on volatility.
        </ListItem>
        <ListItem>
          <ListIcon as={FaCheckCircle} color="teal.500" />
          <Text as="span" fontWeight="bold">
            DCA:
          </Text>{" "}
          Buys more of an asset at regular intervals or when the price falls,
          lowering the average entry price.
        </ListItem>
        <ListItem>
          <ListIcon as={FaCheckCircle} color="teal.500" />
          <Text as="span" fontWeight="bold">
            Momentum:
          </Text>{" "}
          Buys or sells based on the strength and direction of market trends.
        </ListItem>
        <ListItem>
          <ListIcon as={FaCheckCircle} color="teal.500" />
          <Text as="span" fontWeight="bold">
            Range:
          </Text>{" "}
          Buys near support levels and sells near resistance levels.
        </ListItem>
      </List>

    </Box>
  );
}

export default BlindfoldInfo;
