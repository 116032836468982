import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Text } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataLiquidityWavax = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/liquidity_data/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();

      // Get the current date and calculate the timestamp for 2 days ago
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

      // Filter the data to only include entries from the last 2 days
      const filteredData = result.data.filter((entry) => {
        const entryDate = new Date(entry.timestamp);
        return entryDate >= twoDaysAgo;
      });

      setData(filteredData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const mostRecentEntry = data.length > 0 ? data[data.length - 1] : null;
  const mostRecentLongLiquidity = mostRecentEntry ? mostRecentEntry.wavax_long_liquidity : 'N/A';
  const mostRecentShortLiquidity = mostRecentEntry ? mostRecentEntry.wavax_short_liquidity : 'N/A';
  const mostRecentTimestamp = mostRecentEntry ? formatDate(mostRecentEntry.timestamp) : 'N/A';

  const labels = data.map((entry) => formatDate(entry.timestamp));
  const wavaxLongData = data.map((entry) => entry.wavax_long_liquidity);
  const wavaxShortData = data.map((entry) => entry.wavax_short_liquidity);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Long Liquidity',
        data: wavaxLongData,
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        fill: true,
      },
      {
        label: 'Short Liquidity',
        data: wavaxShortData,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Liquidity (USD)',
        },
      },
    },
  };

  return (
    <Box width="100%" height="500px" padding={2} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      {mostRecentEntry && (
        <Box >
          <Text><strong>Long Liquidity:</strong> ${mostRecentLongLiquidity.toFixed(2)}</Text>
          <Text><strong>Short Liquidity:</strong> ${mostRecentShortLiquidity.toFixed(2)}</Text>
          <Text><strong>Timestamp:</strong> {mostRecentTimestamp}</Text>
        </Box>
      )}
      <Box width="100%" height="400px" >
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataLiquidityWavax;
