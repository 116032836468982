import React, { useState, useEffect } from "react";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";

// Register chart.js modules
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataWavaxRsi = () => {
  const [rsiData, setRsiData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = "https://rebekah.cloud/api/wavax_rsi/";

  // Helper function to filter data from the last 7 days
  const filterLast7Days = (data) => {
    const now = new Date();
    const sevenDaysAgo = new Date(now.setDate(now.getDate() - 7)); // Use 7 days instead of 14
    return data.filter((entry) => {
      const timestamp = new Date(entry.timestamp);
      return timestamp >= sevenDaysAgo;
    });
  };

  const fetchRsiValues = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      const rsiEntries = result.data.filter(
        (indicator) => indicator.name === "WAVAX RSI"
      );
      
      // Filter for the last 7 days and sort by most recent first
      const filteredData = filterLast7Days(rsiEntries).reverse();
      
      setRsiData(filteredData.reverse());
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRsiValues(); // Fetch data on initial load

    // Set up interval to fetch data every 3 minutes (180,000 milliseconds)
    const intervalId = setInterval(fetchRsiValues, 180000); 

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  if (loading) return <Spinner />;
  if (error) return <Text>Error: {error}</Text>;

  // Get the most recent RSI entry
  const mostRecentRsi = rsiData.length > 0 ? rsiData[0] : null;
  const mostRecentRsiValue = mostRecentRsi ? mostRecentRsi.value : "N/A";
  const mostRecentRsiTimestamp = mostRecentRsi
    ? new Date(mostRecentRsi.timestamp).toLocaleString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "N/A";

  // Prepare chart data
  const chartLabels = rsiData.map((entry) =>
    new Date(entry.timestamp).toLocaleString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );
  const chartDataValues = rsiData.map((entry) => entry.value);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: "RSI",
        data: chartDataValues,
        fill: false,
        borderColor: "green",
        tension: 0.1,
      },
    ],
  };

  const chartOptions = {
    responsive: true, 
    maintainAspectRatio: false, 
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
         // text: "Date/Time",
        },
        ticks: {
          autoSkip: true, // Skip some labels if there are too many data points
          maxRotation: 90, // Rotate the labels for better readability
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "RSI Value",
        },
      },
    },
  };

  return (
    <Box width="100%" height="500px" p={4} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      <Box marginBottom={1}>
        <Text>
          RSI: {mostRecentRsiValue} (as of {mostRecentRsiTimestamp})
        </Text>
      </Box>
      <Box width="100%" height="100%" mb={2}>
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataWavaxRsi;
