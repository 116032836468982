import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Text } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataNetwork = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://rebekah.cloud/api/network_data/';

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const now = Date.now();
  const last24HoursData = data.filter(
    (entry) => now - new Date(entry.timestamp).getTime() <= 24 * 60 * 60 * 1000
  );

  const mostRecent = last24HoursData.length > 0 ? last24HoursData[last24HoursData.length - 1] : null;

  const labels = last24HoursData.map((entry) => formatDate(entry.timestamp));
  const gasPriceData = last24HoursData.map((entry) => entry.gas_price);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Gas Price',
        data: gasPriceData,
        borderColor: 'green',
        backgroundColor: 'rgba(0, 255, 0, 0.2)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Gas Price',
        },
      },
    },
  };

  return (
    <Box width="100%" height="400px" padding={8} backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      {mostRecent && (
        <Box marginBottom={1}>
          <Text>
            <strong>Gas Price:</strong> {mostRecent.gas_price.toFixed(0)} (as of {formatDate(mostRecent.timestamp)})
          </Text>
        </Box>
      )}
      <Box width="100%" height="100%" mb={8}>
        <Line data={chartData} options={chartOptions} />
      </Box>
    </Box>
  );
};

export default DataNetwork;
