import React, { useState, useEffect } from 'react';
import { Box, Text, VStack } from '@chakra-ui/react';

const DataCollarStatus = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiUrl = 'https://blindfoldcapital.com/api/bot_status/';

  // Fetch data from the API for the last 2 days
  const fetchData = async () => {
    try {
      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
      const formattedDate = twoDaysAgo.toISOString();

      // Add query parameter for filtering by date (if supported by API)
      const urlWithParams = `${apiUrl}?since=${formattedDate}`;
      const response = await fetch(urlWithParams);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  // Function to format the date and time without seconds
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  return (
    <Box
      fontSize="sm"
      borderRadius="md"
      boxShadow="md"
      backgroundColor="gray.50"
      h='265px'
      p={1}
      overflowY="auto"
    >
      <VStack align="start" spacing={3}>
        {data
          .slice()
          .reverse() // Reverse the order to show the most recent first
          .map((entry, index) => (
            <Box key={index} p={2} borderBottom="1px solid #ddd">
              <Text fontWeight="bold">{formatDate(entry.timestamp)}</Text>
              <Text>{entry.status || 'No status available'}</Text>
            </Box>
          ))}
      </VStack>
    </Box>
  );
};

export default DataCollarStatus;
