import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Spinner, Text, Center } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DataStartingValue = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalStartingValues, setTotalStartingValues] = useState(0);
  const [averageStartingValue, setAverageStartingValue] = useState(0);

  const apiUrl = 'https://blindfoldcapital.com/api/starting_values/'; // Update this URL based on your API

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      setData(result.data);

      // Calculate total and average starting values
      const total = result.data.reduce((sum, entry) => sum + entry.starting_value, 0);
      const average = result.data.length ? (total / result.data.length) : 0;

      setTotalStartingValues(total);
      setAverageStartingValue(average);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Text>Loading...</Text>;

  if (error) return <p>Error: {error}</p>;

  // Format timestamp for display
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  // Prepare data for the line chart
  const labels = data.map(entry => formatDate(entry.timestamp));
  const startingValues = data.map(entry => entry.starting_value);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Starting Value',
        data: startingValues,
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.2)',
        fill: true,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category',
        title: {
         // display: true,
         // text: 'Timestamp',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Starting Value',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Starting Value: ${context.raw}`;
          },
        },
      },
    },
  };

  return (
    <Box p={2}   backgroundColor="gray.50" borderRadius="md" boxShadow="md">
      
      <Text fontSize="sm" >
        Av. Starting Value: {averageStartingValue.toFixed(2)}
      </Text>
      <Line data={chartData} options={chartOptions} />
      
      {/* Display total and average starting values */}
      
    </Box>
  );
};

export default DataStartingValue;
