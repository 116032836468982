import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import {
  Box,
  VStack,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  HStack,
  IconButton,
  Tooltip,
  Center,
} from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import JakobWallet from "../../contracts/JakobWallet.json";
import { formatAddress } from "../../utils/formatMetamask";
import { CloseIcon } from "@chakra-ui/icons";
import { AppContext } from "./../../AppContext";
import SwapModal from "./SwapModal";
import DataTradeSettings from "../../api/DataTradeSettings";
import DataAccountData from "../../api/DataAccountData";
import ContractInfo from "./ContractInfo";
import ApprovalModal from "./ApprovalModal";
import AccountApprovalModal from "./AccountApprovalModal";
import AccountDataLink from "./AccountDataLink";

const TenetBox = ({ activeBalance, totalCollateral, handleDataFetched }) => {
  const { rpcUrl, account, contractAddress } = useContext(AppContext);

  // Fallback to JakobWallet.address if activeContractAddress is null
  const activeContractAddress = contractAddress || JakobWallet.address;

  const [nativeBalance, setNativeBalance] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState("");
  const [owner1Address, setOwner1Address] = useState("");
  const [owner2Address, setOwner2Address] = useState("");
  const [owner3Address, setOwner3Address] = useState("");
  const [paymasterAddress, setPaymasterAddress] = useState("");
  const [approval, setApproval] = useState(false);
  const [owner1Approval, setOwner1Approval] = useState(false);
  const [owner2Approval, setOwner2Approval] = useState(false);
  const [owner3Approval, setOwner3Approval] = useState(false);
  const [approvalTimestamp, setApprovalTimestamp] = useState(null);
  const [approvalWindowStatus, setApprovalWindowStatus] = useState("");
  const [contractName, setContractName] = useState("");
  const [pendingUsdcWithdrawal, setPendingUsdcWithdrawal] = useState(null);
  const [paymasterBalance, setPaymasterBalance] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pendingWavaxWithdrawal, setPendingWavaxWithdrawal] = useState(null);
  const [wethAddress, setWethAddress] = useState("");
  const [btcAddress, setBtcAddress] = useState("");
  const [usdcAddress, setUsdcAddress] = useState("");
  const [usdceAddress, setUsdceAddress] = useState("");
  const [wavaxAddress, setWavaxAddress] = useState("");

  //const activeContractAddress = JakobWallet.address;

  useEffect(() => {
    fetchTokenAddresses();
    fetchContractAddresses();
    fetchContractBalance();
    fetchOwnerAddresses();
    fetchContractInfo();
    //fetchPendingAVAXWithdrawal();
    //fetchPendingUSDCWithdrawal();
    // fetchPendingBTCWithdrawal();
    //fetchPendingWETHWithdrawal();
  //  fetchPendingWAVAXWithdrawal();
    fetchOwnerApproval();
    fetchApprovalTimestamp();
  }, []);

  const fetchContractAddresses = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );

      const [positionRouterAddress, routerAddress] = await Promise.all([
        contract.POSITION_ROUTER_ADDRESS(),
        contract.ROUTER_ADDRESS(),
      ]);

      console.log(`Position Router:`, positionRouterAddress);
      console.log(`Router:`, routerAddress);
    } catch (error) {
      console.error("Error fetching token addresses:", error);
    }
  };

  const fetchTokenAddresses = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );

      const [
        usdcAddr,
        usdceAddr,
        wavaxAddr,
        btcAddr,
        wethAddr,
      ] = await Promise.all([
        contract.USDC_ADDRESS(),
        contract.USDCE_ADDRESS(),
        contract.WAVAX_ADDRESS(),
        contract.BTC_ADDRESS(),
        contract.WETH_ADDRESS(),
      ]);

      setUsdcAddress(usdcAddr);
      setUsdceAddress(usdceAddr);
      setWavaxAddress(wavaxAddr);
      setWethAddress(wethAddr);
      setBtcAddress(btcAddr);
    } catch (error) {
      console.error("Error fetching token addresses:", error);
    }
  };

  const fetchContractBalance = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );
      const balance = await contract.getBalance();
      const formattedBalance = ethers.utils.formatEther(balance);
      setNativeBalance(Number(formattedBalance).toFixed(3));
    } catch (error) {
      console.error("Error fetching contract balance:", error);
    }
  };
  const fetchOwnerAddresses = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );

      const [
        owner1Addr,
        owner2Addr,
        owner3Addr,
        paymasterAddress,
      ] = await Promise.all([
        contract.owner1(),
        contract.owner2(),
        contract.owner3(),
        contract.paymaster(),
      ]);

      setOwner1Address(owner1Addr);
      setOwner2Address(owner2Addr);
      setOwner3Address(owner3Addr);
      setPaymasterAddress(paymasterAddress);
    } catch (error) {
      console.error("Error fetching owner addresses:", error);
    }
  };

  const fetchOwnerApproval = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );

      const owner1Approved = await contract.owner1Approval();
      const owner2Approved = await contract.owner2Approval();
      const owner3Approved = await contract.owner3Approval(); // Fetch Owner 3 approval

      setOwner1Approval(owner1Approved);
      setOwner2Approval(owner2Approved);
      setOwner3Approval(owner3Approved); // Set Owner 3 approval
    } catch (error) {
      console.error("Error fetching contract info:", error);
    }
  };

  const fetchContractInfo = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );

      const name = await contract.ContractName();
      setContractName(name);
    } catch (error) {
      console.error("Error fetching contract info:", error);
    }
  };



  const fetchApprovalTimestamp = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        provider
      );

      const timestamp = await contract.approvalTimestamp();
      setApprovalTimestamp(timestamp.toNumber()); // Ensure it's a number

      const now = Math.floor(Date.now() / 1000); // Current time in seconds
      if (timestamp > 0) {
        const timeRemaining = timestamp.add(3600).sub(now); // 3600 seconds = 1 hour
        setApprovalWindowStatus(
          timeRemaining.gt(0)
            ? `Approval window open, ${Math.floor(
                timeRemaining / 60
              )} minutes left`
            : "Approval window expired"
        );
      }
    } catch (error) {
      console.error("Error fetching approval timestamp:", error);
    }
  };

  // Determine if the account is an owner
  const isOwner =
    account === owner1Address ||
    account === owner2Address ||
    account === owner3Address;

  const handleApproval = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        activeContractAddress,
        JakobWallet.abi,
        signer
      );

      const approvalTx = await contract.approveTransfer(true);

      const receipt = await approvalTx.wait();

      setStatus("Granting approval...");
      await approvalTx.wait();

      setStatus(
        `Approval granted! Transaction Hash: ${receipt.transactionHash}`
      );
      setApproval(true);
    } catch (error) {
      console.error("Error granting approval:", error);
      setStatus("Error granting approval.");
    }
  };

  

  // Function to fetch AVAX balance
  const fetchPaymasterBalance = async () => {
    setLoading(true);
    setError(null);
    try {
      // Connect to the AVAX provider using the provided rpcUrl
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);

      // Fetch the balance of the paymaster address
      const balanceInWei = await provider.getBalance(paymasterAddress);

      // Convert balance from wei to AVAX
      const balanceInAvax = parseFloat(
        ethers.utils.formatEther(balanceInWei)
      ).toFixed(3);

      // Update state with the fetched balance
      setPaymasterBalance(balanceInAvax);
    } catch (err) {
      setError(`Error: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (paymasterAddress) {
      fetchPaymasterBalance();
    }
  }, [paymasterAddress]);

  return (
    <VStack p={0} spacing={2} w="100%" h="auto" align="center">
  <Box mt={1} shadow="lg" w={{ base: "100%", md: "100%" }} borderRadius="md">
    <ContractInfo
      contractName={contractName}
      activeContractAddress={activeContractAddress}
      approvalWindowStatus={approvalWindowStatus}
    />
    <Center mt={-2} mb={1}>
      <Text color="black">
        ${activeBalance && activeBalance.toFixed(2)} USD
      </Text>
    </Center>

    <Box bg="gray.50" w="100%">
      <HStack gap={6} justify="center">
        <ApprovalModal
          owners={[
            { address: owner1Address, isApproved: owner1Approval },
            { address: owner2Address, isApproved: owner2Approval },
            { address: owner3Address, isApproved: owner3Approval },
          ]}
          paymasterAddress={paymasterAddress}
          paymasterBalance={paymasterBalance}
          loading={loading}
          error={error}
          handleApproval={handleApproval}
          formatAddress={formatAddress}
        />

        <Tooltip
          label="Settings"
          aria-label="Settings Tooltip"
          placement="top"
        >
          <IconButton
            colorScheme="blue"
            size="md"
            variant="unstyled"
            onClick={onOpen}
            icon={<FiSettings />}
            aria-label="Manage Account"
          />
        </Tooltip>

        <SwapModal />

        <AccountApprovalModal
          owners={[
            { address: owner1Address, isApproved: false },
            { address: owner2Address, isApproved: false },
            { address: owner3Address, isApproved: false },
          ]}
          paymasterAddress={paymasterAddress}
          paymasterBalance={paymasterBalance}
          loading={loading}
          error={error}
          handleApproval={handleApproval}
          formatAddress={formatAddress}
          owner1Approval={owner1Approval}
          owner2Approval={owner2Approval}
          owner3Approval={owner3Approval}
        />

<AccountDataLink />
      </HStack>
    </Box>

    {status && (
      <Text mt={4} color="red.500" fontSize="13px">
        {status}
      </Text>
    )}
  </Box>

  <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="sm">
    <DrawerOverlay />
    <DrawerContent>
      <DrawerHeader p={2} >
        <HStack justifyContent="space-between">
          <Text fontSize="lg">Trade Settings</Text>
          <IconButton
            icon={<CloseIcon />}
            aria-label="Close Drawer"
            onClick={onClose}
            variant="outline"
          />
        </HStack>
      </DrawerHeader>
      <DrawerBody overflowY="auto" height="auto" p={0}>
        <Box   bg="gray.50">
          <Box
           // bg="ghostwhite"
            p={2}
            w="auto"
            h="100%"
            
          >
            <Text fontSize="lg" fontWeight="bold" >
              Account
            </Text>
            <DataAccountData />
          </Box>
          <Box
            //bg="ghostwhite"
            p={2}
            w="auto"
            
            //mt={2}
          >
            <Text fontSize="lg" fontWeight="bold">
              Settings
            </Text>
            <DataTradeSettings onDataFetched={handleDataFetched} />
          </Box>
        </Box>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
</VStack>

  );
};

export default TenetBox;
