import React, { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import { Box, Center, useToast, VStack, Wrap, WrapItem } from "@chakra-ui/react";
import Reader from "./contracts/Reader.json";
import UsdcBalanceFetcher from "../wallet/UsdBalanceFetcher";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { formatAddress } from "../../utils/formatMetamask";
import VaultPriceFeed from "./contracts/VaultPriceFeed.json";
import PositionsGrid from "./PositionsGrid";
import TokenValue from "../wallet/TokenValue";
import CollateralCard from "./CollateralCard";
import UsdceBalanceFetcher from "../wallet/UsdeBalanceFetcher";
ChartJS.register(ArcElement, Tooltip, Legend);

const GmxPositions = ({
  contractAddress,
  onTotalCollateralChange,
  updateTotalBalance,
  handleActiveBalanceUpdate,
}) => {
  const [accountAddress, setAccountAddress] = useState(contractAddress);
  const readerAbi = Reader.abi;
  const toast = useToast();

  const readerAddress = "0x67b789D48c926006F5132BFCe4e976F0A7A63d5D";
  const vaultAddress = "0x9ab2De34A33fB459b538c43f251eB825645e8595";
  const usdc = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";
  const usdc_e = "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664"; // USDC.e
  const wavax = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7";
  const weth = "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB";
  const wbtc = "0xB44a9B6905aF7c801311e8F4E76932ee959c663C";
  const btc = "0x152b9d0FdC40C096757F570A51E494bd4b943E50";
  const [positionsDetails, setPositionsDetails] = useState([]);
  const [totalPositions, setTotalPositions] = useState(0);
  const [totalCollateral, setTotalCollateral] = useState(0);
  const [usdcBalance, setUsdcBalance] = useState(0);
  const [usdceBalance, setUsdceBalance] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [availableCollateral, setAvailableCollateral] = useState(0);
  const [utilizationRate, setUtilizationRate] = useState(0);
  const collateralTokens = [
    wavax,
    usdc,
    wavax,
    wavax,
    weth,
    usdc,
    weth,
    wbtc,
    wbtc,
    wbtc,
    wbtc,
    usdc_e,
    btc,
    usdc,
    usdc,
  ];
  const indexTokens = [
    usdc,
    wavax,
    wavax,
    wavax,
    usdc,
    weth,
    weth,
    usdc,
    wbtc,
    wbtc,
    wbtc,
    wavax,
    btc,
    btc,
    wbtc,
  ];
  const isLong = [
    true,
    false,
    true,
    false,
    true,
    false,
    true,
    true,
    false,
    true,
    false,
    false,
    false,
    false,
    true,
  ];

  // New fixed values
  const sellLimit = 3.0;
  const _lossLimit = 0.6;

  const [showDetails, setShowDetails] = useState(false);
  const [prices, setPrices] = useState({});
  const [reloadBalances, setReloadBalances] = useState(false);

  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const handleGetPositions = async () => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://api.avax.network/ext/bc/C/rpc"
      );
      const contract = new ethers.Contract(readerAddress, readerAbi, provider);

      const positionsData = await contract.getPositions(
        vaultAddress,
        accountAddress,
        collateralTokens,
        indexTokens,
        isLong
      );
      const details = parsePositionsData(positionsData);
      setPositionsDetails(details);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleGetPositions(); // Initial load

    const intervalId = setInterval(() => {
      handleGetPositions();
    }, 20000); // 20 seconds interval

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [tokenPrice]);

  useEffect(() => {
    // Notify parent component of totalCollateral change
    if (onTotalCollateralChange) {
      onTotalCollateralChange(totalCollateral);
    }
  }, [totalCollateral, onTotalCollateralChange]);

  const tokenImages = {
    avax: "./avax-logo.png",
    usdc: "./usdc.png",
    usdc_e: "./usdc.png", // Assuming USDC.e uses the same image as USDC
    wavax: "./avax-logo.png",
    weth: "./weth.png", // Assuming WETH uses the same image as WAVAX
    btc: "./btc.png", // Placeholder image for BTC
    wbtc: "./btc.png",
  };

  // Function to fetch token price
  const fetchPrice = async (tokenAddress) => {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        "https://api.avax.network/ext/bc/C/rpc"
      );
      const routerContract = new ethers.Contract(
        VaultPriceFeed.address,
        VaultPriceFeed.abi,
        provider
      );
      const tx = await routerContract.getPriceV1(tokenAddress, false, true);
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const priceInWei = ethers.BigNumber.from(tx);
      const price = priceInWei.div(scaleFactor);
      return Number(price.toString()) / 1000;
    } catch (err) {
      console.error("Error fetching price:", err);
      return null;
    }
  };

  // Fetch prices for each index token
  useEffect(() => {
    const fetchAllPrices = async () => {
      const newPrices = {};

      for (const position of positionsDetails) {
        const indexTokenAddress =
          indexTokens[position.positionIndex] === wavax
            ? "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
            : indexTokens[position.positionIndex] === usdc
            ? "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664"
            : indexTokens[position.positionIndex] === weth
            ? "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB"
            : indexTokens[position.positionIndex] === btc
            ? "0x152b9d0FdC40C096757F570A51E494bd4b943E50"
            : indexTokens[position.positionIndex] === wbtc
            ? "0x152b9d0FdC40C096757F570A51E494bd4b943E50"
            : null;

        if (indexTokenAddress) {
          const price = await fetchPrice(indexTokenAddress);
          newPrices[position.positionIndex] = price;
        }
      }

      setPrices(newPrices);
    };

    fetchAllPrices();
  }, [positionsDetails]);

  const parsePositionsData = (positionsData) => {
    const details = [];
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    let totalCollateralSize = 0;

    for (let i = 0; i < positionsData.length / 9; i++) {
      const baseIndex = i * 9;
      const sizeInWei = ethers.BigNumber.from(positionsData[baseIndex]);
      const scaleFactor = ethers.BigNumber.from("10").pow(27);
      const formattedSize = sizeInWei.div(scaleFactor);
      const deltaInWei = ethers.BigNumber.from(positionsData[baseIndex + 8]);
      const formattedDelta = deltaInWei.div(scaleFactor);
      const adjustedDelta = Number(formattedDelta.toString()) / 1000;
      const collateralInWei = ethers.BigNumber.from(
        positionsData[baseIndex + 1]
      );
      const formattedCollateral = collateralInWei.div(scaleFactor);
      const entryPriceInWei = ethers.BigNumber.from(
        positionsData[baseIndex + 2]
      );
      const formattedAveragePrice = entryPriceInWei.div(scaleFactor);
      const adjustedSize = Number(formattedSize.toString()) / 1000;
      const adjustedCollateral = Number(formattedCollateral.toString()) / 1000;
      const adjustedAveragePrice =
        Number(formattedAveragePrice.toString()) / 1000;

      if (adjustedSize === 0) continue;

      totalCollateralSize += adjustedCollateral;

      // calculate leverage
      const leverage =
        adjustedCollateral > 0
          ? (adjustedSize / adjustedCollateral).toFixed(2)
          : "N/A";

      // Calculate fees
      const openFee = parseFloat((adjustedSize * 0.001).toFixed(2)); // Calculate 0.01% of the size
      const openCloseFees = parseFloat((openFee * 2).toFixed(2)); // Open fee x 2 for open + close

      const deltaPercentage = parseFloat(
        ((adjustedDelta / adjustedCollateral) * 100).toFixed(2)
      );

      const lastIncreasedTime = new Date(
        positionsData[baseIndex + 6] * 1000
      ).toLocaleString("en-US", {
        timeZone: "America/Chicago",
        year: "2-digit", // Display the year as 24 instead of 2024
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      
      // Calculate target sell price based on position type
      const targetSellPrice = parseFloat(
        isLong[i] === false
          ? adjustedAveragePrice * (1 - sellLimit / 100)
          : adjustedAveragePrice * (1 + sellLimit / 100)
      ).toFixed(2);

      // Calculate target buy price based on position type
      const targetBuyPrice = parseFloat(
        isLong[i] === false
          ? adjustedAveragePrice * (1 + _lossLimit / 100)
          : adjustedAveragePrice * (1 - _lossLimit / 100)
      ).toFixed(2);

      // Calculate PROFIT POTENTIAL
      const positionProfit = adjustedSize * (1 + sellLimit / 100);
      const ps = positionProfit - adjustedSize;
      const potentialProfit = parseFloat((ps - openCloseFees).toFixed(2));

      // has profit value
      const hasProfit = positionsData[baseIndex + 7].toString();

      let percentageChange = parseFloat(
        (
          ((tokenPrice - adjustedAveragePrice) / adjustedAveragePrice) *
          100
        ).toFixed(2)
      );

      // Adjust percentageChange based on position type
      if (!isLong[i]) {
        percentageChange = -percentageChange;
      }

      const tokenName =
        collateralTokens[i] === wavax && indexTokens[i] === usdc
          ? "WAVAX/USDC"
          : collateralTokens[i] === weth && indexTokens[i] === usdc
          ? "WETH/USDC"
          : collateralTokens[i] === wavax && indexTokens[i] === wavax
          ? "WAVAX/WAVAX"
          : collateralTokens[i] === weth && indexTokens[i] === weth
          ? "WETH/WETH"
          : collateralTokens[i] === wbtc && indexTokens[i] === usdc
          ? "WBTC/USDC"
          : collateralTokens[i] === wbtc && indexTokens[i] === wbtc
          ? "WBTC/WBTC"
          : "Unknown Pair";

      details.push({
        positionIndex: i,
        name: tokenName,
        size: adjustedSize.toFixed(2),
        sizeWei: sizeInWei.toString(),
        collateral: adjustedCollateral.toFixed(2),
        collateralWei: collateralInWei.toString(),
        entryPrice: formatter.format(adjustedAveragePrice),
        currentPrice: tokenPrice,
        deltaPercentage: deltaPercentage.toFixed(2) + "%",
        lastIncreasedTime: lastIncreasedTime,
        hasProfit: hasProfit,
        delta: adjustedDelta, // Use plain number for delta
        isLong: isLong[i],
        openClose: openCloseFees,
        sellPrice: targetSellPrice,
        buyPrice: targetBuyPrice,
        potential: potentialProfit.toFixed(2),
        change: percentageChange,
        leverage: leverage,
      });
    }

    setTotalPositions(details.length);
    setTotalCollateral(totalCollateralSize.toFixed(2));

    return details;
  };

  const usdcBalanceRef = useRef(null);

  const handleUsdcBalanceChange = (balance) => {
    console.log("USDC Balance:", balance);
    setUsdcBalance(balance);
  };

  const handleUsdceBalanceChange = (balance) => {
    console.log("USDC.e Balance:", balance);
    setUsdceBalance(balance);
  };

  const reloadUsdcBalance = () => {
    if (usdcBalanceRef.current) {
      usdcBalanceRef.current.reloadBalance();
    }
  };

  useEffect(() => {
    // Calculate total balance whenever usdcBalance or totalCollateral changes
    const tb =
      parseFloat(usdcBalance) +
      parseFloat(totalCollateral) +
      parseFloat(usdceBalance);
    const availCol = parseFloat(usdcBalance) + parseFloat(usdceBalance);
    setTotalBalance(tb.toFixed(2));
    setAvailableCollateral(availCol.toFixed(2));

    // Calculate utilization rate
    if (tb > 0) {
      const utilization = (parseFloat(totalCollateral) / tb) * 100;
      setUtilizationRate(utilization.toFixed(2));
    } else {
      setUtilizationRate(0);
    }
  }, [usdcBalance, usdceBalance]);

  // Function to reload all data, including USDC balance, positions, and total collateral
  const handleReload = async () => {
    // reload collateral utilization rate
    calculateCollateralUtilizationRate();
    // Reload positions
    handleGetPositions();
    // Reload the USDC balance by triggering the UsdcBalanceFetcher component
    reloadUsdcBalance();
    setReloadBalances((prev) => !prev); // Toggle state to trigger reload
    //reloadPrice();
    /** toast({
      title: "Reloading",
      description: "Fetching latest data...",
      status: "info",
      duration: 1000,
      isClosable: true,
      position: "bottom",
    }); */
  };

  // Function to calculate and set collateral utilization rate and total collateral
  const calculateCollateralUtilizationRate = () => {
    const tb =
      parseFloat(usdcBalance) +
      parseFloat(totalCollateral) +
      parseFloat(usdceBalance);
    setTotalBalance(tb.toFixed(2));

    if (tb > 0) {
      const utilization = (parseFloat(totalCollateral) / tb) * 100;
      setUtilizationRate(utilization.toFixed(2));
    } else {
      setUtilizationRate(0);
    }

    // Trigger the onTotalCollateral callback if provided
    if (onTotalCollateralChange) {
      onTotalCollateralChange(totalCollateral);
    }
  };

  // Update collateral utilization whenever `usdcBalance`, `totalCollateral`, or `handleReload` changes
  useEffect(() => {
    calculateCollateralUtilizationRate();
  }, [usdcBalance, usdceBalance, totalCollateral]);

  return (
    <>
      <Wrap
        mt={1}
        mb={5}
        //p={4}
        h="100%"
        minHeight='520px'
        w="100%"
        justify='center'
      //  justify={{ base: "start", md: "center" }} // Center on larger screens, start on mobile
      >
        <WrapItem
          
          shadow="md"
          borderWidth="1px"
          //borderRadius="md"
          //w="100%"
          //w="100%"
          justify="center"
          //maxWidth="300px"
          mb={1}
          bg="gray.50"
          h="100%"
        >
          <VStack p={0} w="100%" justify="center">
            <CollateralCard
              availableCollateral={availableCollateral}
              totalPositions={totalPositions}
              totalCollateral={totalCollateral}
              utilizationRate={utilizationRate}
              handleReload={handleReload}
            />
            <TokenValue
              onActiveBalanceUpdate={handleActiveBalanceUpdate}
              updateTotalBalance={updateTotalBalance}
              totalPositions={totalPositions}
              totalCollateral={totalCollateral}
              reloadBalances={reloadBalances}
            />
            {/**<LiquidityGrid /> */}
          </VStack>
        </WrapItem>
        <WrapItem
          p={2}
          //shadow="md"
          //borderWidth="1px"
          w="100%"
          maxWidth='500px'
          mb={1}
          //bg="gray.50"
          h="100%"
          
          //maxWidth="820px"
          flex="1 0 100%"
        >
          <PositionsGrid
            positionsDetails={positionsDetails}
            collateralTokens={collateralTokens}
            indexTokens={indexTokens}
            prices={prices}
            tokenImages={tokenImages}
            wavax={wavax}
            usdc={usdc}
            usdc_e={usdc_e}
            weth={weth}
            wbtc={wbtc}
            btc={btc}
            showDetails={showDetails}
            toggleDetails={toggleDetails}
            formatAddress={formatAddress}
            sellLimit={sellLimit}
            _lossLimit={_lossLimit}
            totalPositions={totalPositions}
            totalCollateral={totalCollateral}
            utilizationRate={utilizationRate}
            usdcBalance={usdcBalance}
            usdceBalance={usdceBalance}
            handleReload={handleReload}
          />

          {/* Fetch and display USDC balance */}
          <UsdcBalanceFetcher onUsdcBalance={handleUsdcBalanceChange} />
          <UsdceBalanceFetcher onUsdceBalance={handleUsdceBalanceChange} />
        </WrapItem>
        {/** 
        <WrapItem
          p={2}
          shadow="md"
          borderWidth="1px"
          //borderRadius="md"
          w="100%"
justify='center'
          mb={1}
          bg="gray.50"
          h="100%"
        >
         <Center w='100%' p={1}><AccountDataGrid /></Center> 
        </WrapItem>
        */}
      </Wrap>
    </>
  );
};

export default GmxPositions;
